new Vue({
    el: "#site-nav",
    methods: {
        handleChange(event) {
            const form = document.getElementById('selectLanguage');
            const radioNL = document.getElementById('lang_NL');
            const radioUS = document.getElementById('lang_EN');

            if (event.currentTarget.checked) {
                radioNL.checked = true;
            } else {
                radioUS.checked = true;
            }

            form.submit();
        }
    }
});